import React from 'react'
import './Cart.css'
import Navbar from './Navbar'
import { AiFillStar } from 'react-icons/ai';
import Dropdown from './Dropdown';
import Dropdown2 from './Dropdown2';
import IncDecCounter from './Increment';
const Cart = () => {
  return (
    <div    className="fontss">
      <div 
      style={{backgroundColor:'black'}}>
      <Navbar/>

      </div>
        <div className='cart55' >
          <div className='border' style={{display:'flex',justifyContent:'center'}}>
        <div className='left8'>
       <img src={require('../coffeeflavour/raspberry.png')} alt=""/>
        </div>

        <div className='right8'>
            <div>
                <h1 className='heading-coffee flavour-coffeez'>Raspberry</h1>
                <div className='cartt'>

                <div className='star1' style={{color:'gold',display:'flex'}}>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                </div>
                
                <p className='review' style={{textDecoration:'underline'}}>4.7 Ratings</p>
                <p className='review review2 ' style={{textDecoration:'underline'}}>7 Reviews</p>

                </div>
       
                <h1  style={{marginTop:'-10px'}} className='heading-coffee highlight'>Product Details</h1>
                <p className='cartpara'>Free domestic shipping on all prepaid orders above ₹500
If you like your coffee bold and with milk, this South Indian Filter Starter Kit is the ideal piece of equipment to brew yourself a cup of your favourite filter coffee.
</p>
        </div>
        {/* <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
          <h1 className='heading-coffee'>INGREDIENTS</h1>
          <h1 className='heading-c1' >Coffee, Chicory (20%)</h1>

        </div>
        <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
          <h1 className='heading-coffee' >ORIGIN</h1>
          <h1 className='heading-c1' >Pollibetta, Coorg</h1>

        </div>
        <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
          <h1 className='heading-coffee'>FLAVOUR</h1>
          <h1 className='heading-c1' >Deep,Roasty, Heavy Body</h1>

        </div> */}
        {/* <div style={{display:'flex',gap:'2rem',alignItems: "center"}}>
         
          <h1 className='heading-coffee'>ROAST</h1>
          <h1 className='heading-c1' >Medium Dark Roast </h1>

        </div> */}

        <div className='pricee'> 
          <h1 className='price-no' >₹500</h1>
          <button class="button-4" role="button">buy</button>

        </div>
        <div>
          
        </div>
        <div>
        </div>
     {/* <div className='drop'>
      <Dropdown/>
     </div>
   <div className='drop2'>
      <Dropdown2/>
   </div> */}


            </div>
        
          
        </div>
        </div>
        <h1></h1>

    </div>
  )
}

export default Cart