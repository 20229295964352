import React from 'react'

const Card3 = () => {
  return (
    <div>
                <div class="card card3">

<div class="imgBox">
  <img src={require('../../coffeeflavour/vanilla.png')} alt="mouse corsair"
   class="mouse"/>
</div>

<div class="contentBox">
  <h3>VANILLA</h3>
  <h2 class="price">61.<small>98</small> €</h2>
  <a href="#" class="buy"
   style={{backgroundColor:'#ddcf8c'}}
  
  >Buy Now</a>
</div>

</div>
    </div>
  )
}

export default Card3