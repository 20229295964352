import React from 'react'

const Card2 = () => {
  return (
    <div>
         <div
     className='body'
    >
        <div class="card card2" style={{
         
        }}>

<div class="imgBox">
  <img src={require('../../coffeeflavour/irish.png')} alt="mouse corsair"
   class="mouse"/>
</div>

<div class="contentBox">
  <h3>IRISH</h3>
  <h2 class="price">61.<small>98</small> €</h2>
  <a href="#" class="buy " 
  style={{backgroundColor:'#f6c38f'}}
  >Buy Now</a>
</div>

</div>
    </div>
    </div>
  )
}

export default Card2