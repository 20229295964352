import React, { useState } from "react";
import Navbar from './Navbar'
import './About.css'
import {CarouselData} from './CarouselData';
import background1 from '../img/coffee-beans1.png'
import {motion} from 'framer-motion'
import up from '../coffeeflavour/vanilla.png'
import down from '../coffeeflavour/irish.png'
import down2 from '../coffeeflavour/raspberry.png'

const About = () => {
  const transition = {type: 'spring', duration : 3}
  const [selected, setSelected] =useState(0); 
  const tLength = CarouselData.length;

  return (
    
    <div   className="fontss">
    <div
    className='aboutbg1'
    //  style={{backgroundColor:'black'}}
    >
      
      
              <div className='nav' style={{backgroundColor:"black"}}>
              <Navbar/>

              </div>
           <div className='aboutflex'style={{ display: 'flex',
    justifyContent: 'space-around'}}>
            <div className='image-coff' style={{width:"15%"}}>
              < img src={require('../img/coffee-beans1.png')} alt="" className='beans' style={{width:"163%"}} />
            </div>
            <div style={{display: 'flex',alignItems:'center',width:'140%',justifyContent: 'center',textAlign:'center'}}>
            <div className='left-hh' style={{width:'90%'}}>
            <h1 className="heading8" style={{letterSpacing: '5px',    fontSize:'xxx-large'}}>AntidotÉ
              {/* <span>®</span> */}
              <br />
              <span>
        <span style={{color:'rgb(199, 161, 122)'}}>
            {CarouselData[selected].name}
        </span>{" "}
     
        </span> </h1>
     
          <div style={{lineHeight: '27px',
    fontSize: 'x-large'}}>

            <motion.span
          key={selected}
          transition={transition}
          initial={{opacity:0, x:100}}
          animate={{opacity:1,x:0}}
          exit={{opacity:0,x: -100}}
          >
            {CarouselData[selected].review}
        </motion.span>
            
          </div>
          </div>
          </div>
      
        <div className="right-hh bggg " style={{display:'flex'}} > 
      <motion.img 
      //  data-aos-duration="2000"
      key={selected}
      transition={transition}
      initial={{opacity:0, x:100}}
      animate={{opacity:1,x:0}}
      exit={{opacity:0,x: -100}}
      className="imggg" src={CarouselData[selected].image} alt=''/>
      <div className="bgarrow  arrow" style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
marginTop:'10px'}} >


    <div className="boxxx" style={{display: 'flex',
    justifyContent: 'flex-end'}}   data-aos="fade-left"
    data-aos-easing="linear"
    data-aos-duration="1000">
        <img
     style={{width: '20%'}}
   
     onClick={() => {
       selected === 0
       ? setSelected(tLength - 1)
       : setSelected((prev) => prev - 1)
      }}   
      src={up} alt=''/>
      </div>
   
      <div className="boxxx" style={{display: 'flex',
    justifyContent: 'flex-end'}}   data-aos="fade-right"
    data-aos-easing="linear"
    data-aos-duration="1000">
        <img
     style={{width: '20%'}}
   
     onClick={() => {
       selected === 0
       ? setSelected(tLength - 1)
       : setSelected((prev) => prev - 1)
      }}   
      src={down} alt=''/>
      </div>
      <div className="boxxx" style={{display: 'flex',
    justifyContent: 'flex-end'}}   data-aos="fade-left"
    data-aos-easing="linear"
    data-aos-duration="1000">
        <img
     style={{width: '20%'}}
   
     onClick={() => {
       selected === 0
       ? setSelected(tLength - 1)
       : setSelected((prev) => prev - 1)
      }}   
      src={down2} alt=''/>
      </div>
            
</div>
      </div>

      <div className="flavour-heading">
<h1>simmilar flavour</h1>

      </div>
 
   
     
      <div className="mediaQ" >



      <img
    
    //  data-aos="fade-left"
    //  data-aos-easing="linear"
    //  data-aos-duration="1000"
      onClick={() => {
        selected === 0
       ? setSelected(tLength - 1)
       : setSelected((prev) => prev - 1)
      }}   
      src={up} alt=''/>

    
     <img 
      // data-aos="fade-right"
      // data-aos-easing="linear"
      // data-aos-duration="1000"
      onClick={() => {
       selected === 0
       ? setSelected(tLength - 1)
       : setSelected((prev) => prev - 1)
      }}
      src={down} alt=''/>
         <img 
          // data-aos="fade-left"
          // data-aos-easing="linear"
          // data-aos-duration="1000"
          onClick={() => {
            
            selected === 0
            ? setSelected(tLength - 1)
            : setSelected((prev) => prev - 1)
          }}
          src={down2} alt=''/>
          </div>
    </div>

    </div> 





     <span  className="boxx">

      <img   style={{width: '9%'}} src={require('../img/slider1.png')} alt="" />
     </span>
     
  
    

    </div>
   

    
      // </div>
  )
}

export default About