import React from 'react'

const Card4 = () => {
  return (
    <div>
                <div class="card card4">

<div class="imgBox">
  <img src={require('../../coffeeflavour/raspberry.png')} alt="mouse corsair"
   class="mouse"/>
</div>

<div class="contentBox">
  <h3>RASPBERRY</h3>
  <h2 class="price">61.<small>98</small> €</h2>
  <a href="#" class="buy"
  style={{backgroundColor:'#554750'}}
  >Buy Now</a>
</div>

</div>
    </div>
  )
}

export default Card4